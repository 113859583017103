import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
//import RelatedProjects from '../components/CaseStudiesDetails/RelatedProjects'
import details1 from '../../assets/images/projects/projects-details1.jpg'
import project from '../../assets/images/projects/lis-eis.jpg'
import project1 from '../../assets/images/projects/patac1.jpg'
import project2 from '../../assets/images/projects/patac2.jpg'
import project3 from '../../assets/images/projects/patac3.jpg'
import {Link} from 'gatsby'
import Lightbox from 'react-image-lightbox'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="LIS & EIS UNHCR Platform" 
                homePageText="App development" 
                homePageUrl="/app-development" 
                activePageText="LIS & EIS UNHCR Platform" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Cliente:</span>
                        <a href="https://envytheme.com/" target="_blank" rel="noreferrer">UNHCR</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        World
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Tecnologia:</span>
                        Django + Angular
                        </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Durata progetto:</span>
                        2 Anni 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Stato progetto:</span>
                        Concluso
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-globe'></i>
                        </div>
                        <span>Link al progetto:</span>
                        <a href="https://lis.unhcr.org" target="_blank" rel="noreferrer">LIS</a>
                        <a href="https://eis.unhcr.org" target="_blank" rel="noreferrer">EIS</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={project} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">App development</span>
                            <h3>The project</h3>
                            <p>In 2017, Gnucoop was also awarded  a second project by UNHCR, regarding the development of a web application for data display/analysis of Livelihood and Energy data from UNHCR camps worldwide.  </p>
                            <p>Data is collected using KoBoToolbox. The energy and livelihood platform is a webportal that facilitates end-user/stakeholder access to important operational and decision-making data. The system integrates data from an external source (e.g. Google sheet), manages the calculation of indicators, the displaying on the interface and manages different filters, allowing the user to download the search results (from table, based on user-selected parameters and the raw data imported in the platform database).
                            </p>
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="content">
                                        <h3>Platform features</h3>
                                        <p>Some of the most important features of the application are:</p> 
                                        <ul>
                                            <li>a section for uploading data from excel/google sheet, or from any other program that is able to extract in CSV format,  to the platform;   </li>
                                            <li>a multi function admin section for users/permissions, files management, text box section management;  </li>
                                            <li>landing page (narrowed down from global to country level by interactive map);</li>
                                            <li>dashboard and country page to display indicators applying different filters and display data through different aggregation levels (geolevel, timelevel, organizations, and following customized solution);   </li>
                                            <li>repository section managed by user with Administration rights. It’s used for managing documents related to the different platform sections.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

const images = [
    (project1 ),
    (project2),
    (project3),
];

const Gallery = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <section>
            <div className="gallery-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(false); 
                                        setPhotoIndex(0);}
                                    }
                                >
                                    <img src={project1} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(1);}
                                    }
                                >
                                    <img src={project2} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(2);}
                                    }
                                >
                                    <img src={project3} alt="event" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
            </section>
    );
}
